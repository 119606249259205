import { Autocomplete } from '../lib/Autocomplete';

export class search {
    constructor() {
        this.search();
    }

    search(): void {
        // search form handling
        document
            .querySelectorAll<HTMLFormElement>('.solr-search__form-header, .solr-search__form-static')
            .forEach((searchForm) => {
                const input = searchForm.querySelector<HTMLInputElement>(
                    '.solr-search__form-input'
                ) as HTMLInputElement;
                if (searchForm.classList.contains('solr-search__form-static')) {
                    const formInputWrapper = searchForm.querySelector(
                        '.solr-search__form-static__wrapper'
                    ) as HTMLElement;
                    Autocomplete.create(formInputWrapper, searchForm, input!);
                } else {
                    const formInputWrapper = searchForm.querySelector<HTMLElement>(
                        '.solr-search__wrapper'
                    ) as HTMLElement;
                    const openBtn = searchForm.previousElementSibling as HTMLButtonElement;
                    const submitBtn = searchForm.querySelector<HTMLButtonElement>(
                        '.solr-search__form-submit'
                    ) as HTMLButtonElement;

                    Autocomplete.create(formInputWrapper, searchForm, input!);

                    openBtn?.addEventListener('click', () => {
                        const openNav = document.querySelector('.collapse__first-level.show');
                        searchForm.classList.toggle('open');
                        submitBtn.style.zIndex = '0';
                        openBtn.style.zIndex = '10';
                        searchForm.style.width = document.getElementById('main-nav')!.clientWidth + 'px';
                        if (searchForm.classList.contains('open')) {
                            setTimeout(() => {
                                input.focus();
                            }, 25);
                        }

                        if (openNav) {
                            (openNav.previousElementSibling as HTMLElement).click();
                        }
                    });

                    input.addEventListener('input', () => {
                        if (input.value.length == 0) {
                            submitBtn.style.zIndex = '0';
                            openBtn ? (openBtn.style.zIndex = '10') : '';
                        } else {
                            submitBtn.style.zIndex = '10';
                            openBtn ? (openBtn.style.zIndex = '0') : '';
                        }
                    });
                }
            });
    }
}
