import { buildDom, DOMElement, elOf } from './DomBuilder';

interface SuggestDocument {
    link: string;
    type: string;
    title: string;
    content: string;
    group: string;
    previewImage: string;
}

type SuggestTerms = { [index: string]: number };

interface SuggestResponse {
    status?: false;
    didSecondSearch: boolean;
    suggestion: string;
    suggestions: SuggestTerms;
    documents: SuggestDocument[];
}

export class Autocomplete {
    readonly #container: HTMLElement;
    readonly #form: HTMLFormElement;
    readonly #input: HTMLInputElement;
    readonly #url: string;

    readonly #deactivateFunc: (e: Event) => void;
    readonly #listContainer: HTMLElement;
    readonly #inputFunc: () => void;
    readonly #clickHandler: (e: MouseEvent) => void;
    readonly #keyHandler: (e: KeyboardEvent) => void;
    readonly #hoverFunc: (e: MouseEvent) => void;

    #inputTO: number = 0;

    constructor(container: HTMLElement, form: HTMLFormElement, input: HTMLInputElement) {
        this.#container = container;
        this.#form = form;
        this.#input = input;
        this.#url = this.#form.dataset.suggest as string;
        this.#listContainer = document.createElement('div');
        this.#listContainer.classList.add('solr-search__list-container', 'position-absolute');

        this.#container.appendChild(this.#listContainer);

        this.#inputFunc = (): void => {
            this.handle();
        };

        this.#keyHandler = (e: KeyboardEvent): void => {
            switch (e.key) {
                case 'ArrowDown':
                    this.moveCursor(e, 1);
                    break;
                case 'ArrowUp':
                    this.moveCursor(e, -1);
                    break;
                case 'Escape':
                    this.deactivate();
                    break;
                case 'Enter':
                    const active = this.#listContainer.querySelector<HTMLElement>('a.hover');
                    if (active != null) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.stopImmediatePropagation();

                        if (active.dataset.autocompleteUrl) {
                            location.href = active.dataset.autocompleteUrl;
                        } else if (active.dataset.autocompleteTerm) {
                            this.select(active.dataset.autocompleteTerm);
                        }
                    }
                    break;
            }
        };

        this.#clickHandler = (e: MouseEvent): void => {
            const clicked = e.target as HTMLElement;
            const target = clicked?.closest('a');

            if (target?.dataset.autocompleteTerm) {
                this.select(target.dataset.autocompleteTerm);
                e.preventDefault();
            } else if (target?.dataset.autocompleteUrl) {
                location.href = target.dataset.autocompleteUrl;
            }
            e.stopPropagation();
        };

        this.#deactivateFunc = (e: Event): void => {
            let p: ParentNode | null = e.target as ParentNode;

            if (!p) {
                return;
            }

            while (p) {
                if (this.#container.isSameNode(p)) {
                    return;
                }
                p = p.parentNode;
            }

            this.deactivate();
        };

        this.#hoverFunc = (e: MouseEvent): void => {
            const el = e.target as HTMLAnchorElement;
            if (el.tagName == 'A') {
                this.#listContainer.querySelectorAll('a.hover').forEach((el: Element) => el.classList.remove('hover'));
                el.classList.add('hover');
            }
        };

        this.#input.addEventListener('focus', (): void => {
            this.activate();
        });
    }

    private activate() {
        this.#input.parentNode?.appendChild(this.#listContainer);
        this.#input.addEventListener('input', this.#inputFunc);
        this.#input.addEventListener('keydown', this.#keyHandler);
        this.#container.addEventListener('click', this.#clickHandler);
        this.#container.addEventListener('mouseover', this.#hoverFunc);
        this.handle();
        window.addEventListener('click', this.#deactivateFunc);
    }

    private deactivate() {
        this.#input.parentNode?.removeChild(this.#listContainer);
        this.#input.removeEventListener('input', this.#inputFunc);
        this.#input.removeEventListener('keydown', this.#keyHandler);
        this.#container.removeEventListener('click', this.#clickHandler);
        this.#container.removeEventListener('mouseover', this.#hoverFunc);
        this.#form.classList.remove('open');
        const openBtn = this.#container.querySelector<HTMLButtonElement>(
            '.solr-search__form-header .solr-search__toggle'
        );
        const submitBtn = this.#container.querySelector<HTMLButtonElement>(
            '.solr-search__form-header .solr-search__form-submit'
        );
        openBtn ? (openBtn.style.zIndex = '10') : '';
        submitBtn ? (submitBtn.style.zIndex = '0') : '';
        window.removeEventListener('click', this.#deactivateFunc);
        this.#input.blur();
    }

    private renderBox(...elements: DOMElement[]): void {
        while (this.#listContainer.firstChild) {
            this.#listContainer.removeChild(this.#listContainer.firstChild);
        }

        if (elements.length > 0) {
            buildDom(this.#listContainer, elements);
        }
    }

    /**
     * @TODO: this might be not so charming. maybe some1 could refactor this.
     *
     * @param search
     * @param text
     * @private
     */
    private highlightWords(search: string, text: string) {
        const index = text.indexOf(search);
        const markedWord: DOMElement[] = [];
        const prefix = elOf('span', null, text.substring(0, index));
        const mark = elOf('mark', null, text.substring(index, index + search.length));
        const suffix = elOf('span', null, text.substring(index + search.length));
        markedWord.push(elOf('span', null, [prefix, mark, suffix]));

        return markedWord;
    }

    private handle(): void {
        if (this.#inputTO) {
            clearTimeout(this.#inputTO);
        }

        // @ts-ignore
        this.#inputTO = setTimeout(async () => {
            if (this.#input.value.trim() == '') {
                this.renderBox();
                return;
            }

            const url = this.#url + '?tx_solr[queryString]=' + encodeURIComponent(this.#input.value);
            const resp: SuggestResponse = await (await fetch(url)).json();
            const lists: DOMElement[] = [];

            if (resp?.status !== false) {
                const suggests = Object.keys(resp.suggestions);

                if (suggests.length > 0) {
                    const suggestEls: DOMElement[] = [];
                    for (const term of suggests) {
                        const a = elOf(
                            'a',
                            { 'data-autocomplete-term': term, href: '#' },
                            this.highlightWords(this.#input.value, term)
                        );
                        suggestEls.push(elOf('li', null, [a]));
                    }
                    lists.push(elOf('ul', null, suggestEls));
                }

                if (resp.documents.length > 0) {
                    const docEls: DOMElement[] = [];
                    resp.documents.forEach((el: SuggestDocument): void => {
                        let title = el.title;

                        if (title.length > 30) {
                            title = title.substring(0, 30) + ' …';
                        }

                        const a = elOf(
                            'a',
                            { 'data-autocomplete-url': el.link, href: el.link, title: el.title },
                            title
                        );
                        docEls.push(elOf('li', null, [a]));
                    });
                    lists.push(elOf('ul', null, docEls));
                }
            }

            if (lists.length) {
                this.renderBox(elOf('div', 'tx-solr-suggest-box', lists));
            } else {
                this.renderBox();
            }
        }, 100);
    }

    static create(container: HTMLElement, form: HTMLFormElement, input: HTMLInputElement) {
        return new Autocomplete(container, form, input);
    }

    private select(term: string) {
        this.#input.value = term;
        this.deactivate();
        this.#form.submit();
    }

    private moveCursor(e: KeyboardEvent, offset: number) {
        const elements = this.#listContainer.querySelectorAll('a');
        let active = -1;

        for (const [i, el] of elements.entries()) {
            if (el.classList.contains('hover')) {
                active = i;
                el.classList.remove('hover');
            }
        }

        active += offset;
        if (active >= elements.length) {
            active = 0;
        } else if (active < 0) {
            active = elements.length - 1;
        }

        elements[active].classList.add('hover');

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
    }
}
