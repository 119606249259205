import { Supi } from '../../../../../../vendor/supseven/supi/Build/Src/JavaScript/Supi';
export class supi {
    constructor() {
        // add title and classes to supi-youtube iframe for a11y and visual reasons
        document.addEventListener('youTubeAllowed', (e) => {
            // @ts-ignore
            const title = e.detail.datset.title;

            // @ts-ignore
            const iframe = e.detail.iframe as HTMLIFrameElement;
            iframe.title = title;
            iframe.classList.add('w-100');
            iframe.classList.add('aspect-ratio-16by9');
        });

        (window as any).supi = new Supi();
    }
}
