export class modal {
    constructor() {
        this.init();
    }

    init(): void {
        window.addEventListener('show.bs.modal', (e) => {
            const _el = e.target;
            const scrollPosition = document.documentElement.scrollTop;
            window.location.hash = (_el as HTMLElement).id;
            document.documentElement.scrollTop = scrollPosition;
        });

        window.addEventListener('hide.bs.modal', () => {
            // @ts-ignore
            const url = new URL(window.location);
            url.hash = '';
            window.history.pushState({}, '', url);
        });

        if (window.location.hash) {
            const hash = window.location.hash;
            const modal = document.getElementById(hash.substring(1));
            if (modal && modal.classList.contains('modal')) {
                const btn = modal?.parentElement?.querySelector('button[data-bs-target]') as HTMLButtonElement;
                btn?.click();
            }
        }
    }
}
