export class quicklinks {
    constructor() {
        this.init();
    }

    init(): void {
        // show hidden quicklink links
        const quickLinkToggles = document.querySelectorAll('.toggle-quicklinks');
        quickLinkToggles.forEach((quickLinkToggle) => {
            quickLinkToggle.addEventListener('click', () => {
                const links: NodeListOf<HTMLElement> =
                    quickLinkToggle.previousElementSibling?.querySelectorAll('.navigation__item')!;
                links.forEach((link) => {
                    if (link.classList.contains('hidden')) {
                        link.classList.remove('hidden');
                    }
                });

                //focus 7th link for a11y reasons
                setTimeout(() => {
                    (links[6].children[0] as HTMLAnchorElement).focus();
                }, 100);

                quickLinkToggle.classList.add('d-none');
            });
        });
    }
}
