export class PageHeader {
    elem: HTMLElement | null = null;

    constructor() {
        this.elem = document.querySelector('.frame-type-theme_page_header picture');

        if (this.elem) {
            this.calc();

            window.addEventListener('resize', () => {
                this.calc();
            });
        }
    }

    calc() {
        const img = this.elem?.querySelector('img');

        (async () => {
            await this.waitForImage(img ?? new Image());
        })().then(() => {
            document.documentElement.style.setProperty('--picture-height', img?.offsetHeight + 'px');
        });
    }

    waitForImage(imgElem: HTMLImageElement) {
        return new Promise<void>((res) => {
            if (imgElem.complete) {
                return res();
            }
            imgElem.onload = () => res();
            imgElem.onerror = () => res();
        });
    }
}
