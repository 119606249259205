import { ParentUntil } from '../helper/parentUntil';

export class Slider {
    wrapper: NodeListOf<HTMLElement>;

    constructor(selector: string) {
        this.wrapper = document.querySelectorAll(selector);

        setTimeout(() => {
            this.init();
        }, 250);

        window.addEventListener('resize', () => {
            this.isInViewport();
        });
    }

    init() {
        this.isInViewport();
        this.tab();

        this.wrapper.forEach((slider: HTMLElement) => {
            const prev: HTMLButtonElement | null | undefined =
                slider.parentElement?.parentElement?.parentElement?.querySelector<HTMLButtonElement>('.prev');
            const next: HTMLButtonElement | null | undefined =
                slider.parentElement?.parentElement?.parentElement?.querySelector<HTMLButtonElement>('.next');
            const slides: HTMLElement | null = slider.querySelector('.slides') as HTMLElement;

            if (slides.children.length <= 3) {
                const buttonWrapper = new ParentUntil(next, 'container-xl').get() as HTMLElement;
                buttonWrapper.remove();
            }

            if (slides.children.length <= 0) {
                return;
            }

            const gap: number = parseInt(getComputedStyle(slides).gap);
            const item: HTMLElement | null = slides?.querySelector('.slides__item');
            const first = slides?.querySelector('.first') as HTMLElement;
            const last = slides?.querySelector('.last') as HTMLElement;
            const width: number = item?.offsetWidth ?? 0;

            let lastMove: boolean = false;
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    lastMove = entry.isIntersecting;
                });
            });

            observer.observe(last);

            let move: number = 0;
            let lastMoveTo: number = 0;
            let lastMoveToSave: number = 0;

            next?.addEventListener('click', (e) => {
                if (e.detail > 1) {
                    return;
                }

                const slided: HTMLElement | null = slides?.querySelector('.slides__item.slided');
                slided?.classList.remove('slided');
                slided?.nextElementSibling?.classList.add('slided');

                const active: HTMLElement | null = slides?.querySelector('.slides__item.active');
                active?.classList.remove('active');
                active?.nextElementSibling?.classList.add('active');

                if (lastMove) {
                    lastMoveTo = window.innerWidth - (last?.getBoundingClientRect().right + gap);
                    move += lastMoveTo;
                } else {
                    if (active?.classList.contains('first')) {
                        move = 0;
                    }

                    move += (width + gap) * -1;
                }

                if (slided?.nextElementSibling?.classList.contains('last') || lastMove) {
                    slided?.nextElementSibling?.classList.remove('slided');
                    last?.classList.add('slided');
                    if (lastMoveTo !== 0) {
                        lastMoveToSave = lastMoveTo;
                    }
                }

                slides?.style.setProperty('transform', 'translateX(' + move + 'px)');

                if (last?.classList.contains('active')) {
                    next.setAttribute('disabled', 'disabled');
                }

                prev?.removeAttribute('disabled');
            });

            prev?.addEventListener('click', (e) => {
                if (e.detail > 1) {
                    return;
                }

                lastMove = last.classList.contains('slided') ?? false;

                const slided: HTMLElement | null = slides?.querySelector('.slides__item.slided');
                slided?.classList.remove('slided');
                slided?.previousElementSibling?.classList.add('slided');

                const active: HTMLElement | null = slides?.querySelector('.slides__item.active');
                active?.classList.remove('active');
                active?.previousElementSibling?.classList.add('active');

                if (lastMove) {
                    move -= lastMoveToSave;
                } else {
                    move -= (width + gap) * -1;
                }

                if (slided?.previousElementSibling?.classList.contains('first') || move <= 0) {
                    slided?.previousElementSibling?.classList.remove('slided');
                    first?.classList.add('slided');
                }

                if (move <= 0) {
                    slides?.style.setProperty('transform', 'translateX(' + move + 'px)');
                }

                if (first?.classList.contains('active')) {
                    prev.setAttribute('disabled', 'disabled');
                    first?.classList.add('slided');
                }

                next?.removeAttribute('disabled');
            });
        });
    }

    tab() {
        document.addEventListener('keyup', (e) => {
            if (e.key === 'Tab') {
                const target = e.target as HTMLElement;
                const item =
                    target.parentElement?.parentElement?.getAttribute('tabindex') === '-1'
                        ? target.parentElement?.parentElement
                        : target;

                if (item.classList.contains('slides__item')) {
                    const wrapper = new ParentUntil(item, 'frame').get();
                    const next = wrapper?.querySelector('.next') as HTMLElement;
                    const prev = wrapper?.querySelector('.prev') as HTMLElement;
                    const btnWrap = new ParentUntil(next, 'container-fluid').get();

                    if (btnWrap) {
                        if (window.getComputedStyle(btnWrap).getPropertyValue('display') === 'none') {
                            return;
                        }
                    }

                    if (e.shiftKey) {
                        prev?.click();
                    } else {
                        if (!item.classList.contains('first')) {
                            next?.click();
                        }
                    }
                }
            }
        });
    }

    isInViewport() {
        this.wrapper.forEach((slider: HTMLElement) => {
            const slides: HTMLElement | null = slider.querySelector('.slides') as HTMLElement;

            if (slides.children.length <= 0) {
                return;
            }

            const item: HTMLElement | null = slides?.querySelector('.slides__item') as HTMLElement;
            const width: number = item?.offsetWidth ?? 0;
            const gap: number = parseInt(getComputedStyle(slides).gap);
            const last = slides?.querySelector('.last') as HTMLElement;
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio > 0) {
                            slider.parentElement?.parentElement?.classList.add('no-slider');
                            observer.unobserve(entry.target);
                        } else {
                            slider.parentElement?.parentElement?.classList.remove('no-slider');
                        }
                    });
                },
                { rootMargin: (width + gap) * -1 + 'px' }
            );

            observer.observe(last);
        });
    }
}
