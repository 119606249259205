export class ParentUntil {
    element: HTMLElement | null | undefined;

    className: string;

    constructor(element: HTMLElement | null | undefined, className: string) {
        if (element) {
            this.element = element;
        }

        this.className = className;
    }

    get(): HTMLElement | null {
        let currentElement = this.element;

        while (currentElement) {
            if (currentElement.classList.contains(this.className)) {
                return currentElement;
            }

            currentElement = currentElement.parentElement as HTMLElement;
        }

        return null;
    }
}
