export class Lazy {
    constructor() {
        const body = document.getElementById('top');
        const images = document.querySelectorAll('[loading="lazy"]');

        const observer = new IntersectionObserver((entries, o) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.removeAttribute('loading');

                    if (body?.classList.contains('develop')) {
                        console.log({
                            message: 'removed loading attribute',
                            element: entry.target,
                        });
                    }
                }
            });

            setTimeout(() => {
                o.disconnect();
            }, 200);
        });

        images.forEach((image) => {
            observer.observe(image);
        });
    }
}
