export class Filter {
    private _initQueryString: string = 'tx_news_pi1[overwriteDemand]';

    private _urlParts: { categories: number[]; month: number[]; year: number[] } = {
        categories: [],
        month: [],
        year: [],
    };

    private readonly _form: HTMLFormElement | null = null;

    private _uri: URL | undefined;

    private _checkboxes: NodeListOf<HTMLInputElement> | null = null;

    private _lists: { categories: HTMLUListElement | null; dates: HTMLUListElement | null } = {
        categories: null,
        dates: null,
    };

    private checked: NodeListOf<HTMLInputElement> | undefined;

    private cHash: string = '';

    constructor() {
        this._form = document.getElementById('news-filter') as HTMLFormElement;

        if (!this._form) {
            return;
        }

        this._checkboxes = this._form?.querySelectorAll('input[type="checkbox"]');
        this._uri = new URL(this._form.action);
        this._lists = {
            categories: document.querySelector('#categories > ul'),
            dates: document.querySelector('#dates > ul'),
        };

        this._checkboxes?.forEach((_checkbox) => {
            this.triggerChange(_checkbox as HTMLInputElement);
        });

        this.triggerSubmit();
        this.triggerClose();
        this.triggerRemoveFilter();
    }

    triggerRemoveFilter() {
        const filter = this._form?.nextElementSibling;
        const remove = filter?.querySelectorAll('a');

        remove?.forEach((element) => {
            element.addEventListener('click', () => {
                this.showSpinner();
            });
        });
    }

    triggerClose() {
        document.addEventListener('keyup', (e) => {
            if (e.key === 'Escape') {
                document.querySelectorAll('.news-filter [data-bs-toggle][aria-expanded="true"]').forEach((btn) => {
                    const toggle = btn as HTMLButtonElement;
                    toggle.click();
                });
            }
        });

        document.addEventListener('click', (e) => {
            const target = e.target as HTMLElement;

            if (!target.closest('.news-filter__select')) {
                document.querySelectorAll('.news-filter [data-bs-toggle][aria-expanded="true"]').forEach((btn) => {
                    const toggle = btn as HTMLButtonElement;
                    toggle.click();
                });
            }
        });
    }

    triggerChange(_checkbox: HTMLInputElement) {
        _checkbox.addEventListener('change', () => {
            // dates checkbox is only single select due to limitations of the ext:news
            if (_checkbox.name === 'dates') {
                this._form?.querySelectorAll('input[name="dates"]:checked').forEach((elem) => {
                    const uncheck = elem as HTMLInputElement;
                    if (uncheck.value !== _checkbox.value) {
                        uncheck.checked = false;
                    }
                });
            }

            const checkedCategories = this._form?.querySelectorAll('input[name="categories"]:checked').length ?? 0;
            const checkedDates = this._form?.querySelectorAll('input[name="dates"]:checked').length ?? 0;
            const searchUrl: URL = new URL((this._uri?.origin ?? '') + _checkbox.dataset.optionsUrl);
            const checkedValues: number[] = [];

            this._form?.querySelectorAll('input[name="categories"]:checked').forEach((checkedElem) => {
                const checkbox = checkedElem as HTMLInputElement;
                checkedValues.push(parseInt(checkbox.value));
            });

            searchUrl.searchParams.set('tx_news_pi1[overwriteDemand][categories]', checkedValues.join(','));

            const fetchUrl =
                checkedCategories > 0 || checkedDates > 0 ? searchUrl.href : searchUrl.origin + searchUrl.pathname;
            if (fetchUrl) {
                fetch(fetchUrl)
                    .then((response) => {
                        if (!response.ok) {
                            console.error('Loading failed.');
                        }
                        return response.json();
                    })
                    .then((data) => {
                        const _items = data[_checkbox.name === 'categories' ? 'dates' : 'categories']['items'];

                        if (_checkbox.name === 'categories') {
                            this.removeItems(this._lists.dates);

                            // @ts-ignore
                            for (const [key, value] of Object.entries(_items).reverse()) {
                                // @ts-ignore
                                const date = new Date(value.date.date);
                                const dateFormatted = date.toLocaleDateString('de-AT', {
                                    month: 'long',
                                    year: 'numeric',
                                });

                                this.createElement(
                                    date.getFullYear() + '-' + (date.getMonth() + 1) + '-1',
                                    // @ts-ignore
                                    value.url.filter,
                                    dateFormatted,
                                    // @ts-ignore
                                    this._lists.dates,
                                    'dates'
                                );
                            }
                        }

                        if (_checkbox.name === 'dates') {
                            this.removeItems(this._lists.categories);

                            // @ts-ignore
                            for (const [key, value] of Object.entries(_items).reverse()) {
                                // @ts-ignore
                                this.createElement(
                                    // @ts-ignore
                                    value.data.uid,
                                    // @ts-ignore
                                    value.url.filter,
                                    // @ts-ignore
                                    value.data.title,
                                    // @ts-ignore
                                    this._lists.categories,
                                    'categories'
                                );
                            }
                        }
                    })
                    .catch(() => {
                        console.error('Catch error.');
                    });
            }
        });
    }

    triggerSubmit() {
        this._form?.addEventListener('submit', (e) => {
            e.preventDefault();
            this.showSpinner();

            const checkboxes = this._form?.querySelectorAll('input[type="checkbox"]');
            this.cHash = this.getCHash(checkboxes as NodeListOf<HTMLInputElement>);

            checkboxes?.forEach((v: Element) => {
                const _checkbox = v as HTMLInputElement;
                if (_checkbox.checked) {
                    if (_checkbox.name === 'dates') {
                        const _date = new Date(this.reformatDate(_checkbox.value));
                        this._urlParts.month.push(_date.getMonth() + 1);
                        this._urlParts.year.push(_date.getFullYear());
                    }

                    if (_checkbox.name === 'categories') {
                        this._urlParts.categories.push(parseInt(_checkbox.value));
                    }
                }
            });

            for (const [key, value] of Object.entries(this._urlParts)) {
                if (value.length > 0) {
                    const _queryString: string = this._initQueryString + '[' + key + ']';
                    this._uri?.searchParams.append(_queryString, value.join());
                }
            }

            if (this.cHash !== '') {
                this._uri?.searchParams.append('cHash', this.cHash);
            }

            document.location.href = this._uri?.href ?? '';
        });
    }

    reformatDate(date: string): string {
        const [year, month, day] = date.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }

    getCHash(nodeList: NodeListOf<HTMLInputElement>): string {
        const searchParams = new URLSearchParams(nodeList[0].dataset.optionsUrl);
        return searchParams.get('cHash') ?? '';
    }

    removeItems(item: any) {
        this.checked = item.querySelectorAll('input:checked');

        while (item?.firstChild) {
            item?.removeChild(item?.firstChild);
        }
    }

    createElement(value: string, url: string, text: string, prependTo: HTMLUListElement, name: string) {
        const li = document.createElement('li');

        const label = document.createElement('label');
        label.classList.add('news-filter__items-item');
        label.classList.add('text-decoration-none');
        label.classList.add('text-bodycolor');
        label.classList.add('d-flex');
        label.classList.add('flex-column');

        const span = document.createElement('span');
        span.textContent = text;

        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.value = value;
        checkbox.dataset.optionsUrl = url;
        checkbox.name = name;
        checkbox.checked = this.isChecked(value);

        label.prepend(span);
        label.prepend(checkbox);

        li.prepend(label);

        prependTo.prepend(li);

        this.triggerChange(checkbox);
    }

    isChecked(value: string | number): boolean {
        let isChecked = false;

        if (this.checked) {
            for (let i = 0; i < this.checked?.length; i++) {
                const checkedValue =
                    typeof value === 'number' ? parseInt(this.checked[i].value) : this.checked[i].value;

                if (checkedValue === value) {
                    isChecked = true;
                    break;
                }
            }
        }

        return isChecked;
    }

    showSpinner() {
        // @ts-ignore
        const a11yValue = typeof TYPO3.lang !== 'undefined' ? TYPO3.lang['project.loading'] : 'Wird geladen ...';

        const spinner = document.createElement('dialog');
        spinner.classList.add('spinner');
        spinner.innerHTML =
            '<span class="visually-hidden">' +
            a11yValue +
            '</span><svg width="100" height="100" aria-hidden="true"><use xlink:href="#spinner-loading"/></svg>';
        document.body.appendChild(spinner);
        spinner.showModal();
    }
}
