export class GetLargestElement {
    heights: number[] = [];

    constructor(elem: string, applyTo: HTMLElement | null, name: string) {
        setTimeout(() => {
            this.calc(elem, applyTo, name);
        }, 50);

        window.addEventListener('resize', () => {
            this.calc(elem, applyTo, name);
        });
    }

    calc(elem: string, applyTo: HTMLElement | null, name: string) {
        this.heights = [];

        const elems: NodeListOf<HTMLElement> = document.querySelectorAll(elem);

        if (elems.length <= 0) {
            return;
        }

        elems.forEach((elem: HTMLElement) => {
            this.heights.push(elem.offsetHeight);
        });

        if (!applyTo) {
            console.warn('nothing to apply to');
            return;
        }

        applyTo.style.setProperty(name, Math.max(...this.heights) / 2 + 'px');
    }
}
