// components
import { supi } from './components/supi';
import { search } from './components/search';
import { navigation } from './components/navigation';
import { quicklinks } from './components/quicklinks';
import { modal } from './components/modal';
import { Slider } from './components/slider';
import { PageHeader } from './components/PageHeader';
import { Filter } from './components/filter';

// santas little helper
import { GetLargestElement } from './helper/getLargestElement';
import { Lazy } from './helper/lazy';
// import { LayoutShifts } from './helper/layout-shifts';

// Global imports
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';

function init() {
    // // supi implementation
    new supi();

    // search functionality
    new search();

    // navigation handling
    new navigation();

    // quicklinks handling
    new quicklinks();

    // modal handling
    new modal();

    // slider
    new Slider('[data-slides-fullwidth]');

    // page header
    new PageHeader();

    // news filter
    new Filter();

    // card height calculation
    new GetLargestElement('.card-big__content-wrapper', document.querySelector(':root'), '--card-big-offset');

    // lazy loading
    new Lazy();

    // test layoushifts on develop only
    // new LayoutShifts();
}

if (['complete', 'interactive', 'loaded'].includes(document.readyState)) {
    init();
} else {
    document.addEventListener('DOMContentLoaded', () => init(), { once: true });
}
